@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@charset "UTF-8";

@font-face {
  font-family: "icons";
  src:url(/static/media/icons.b729feae.eot);
  src:url(/static/media/icons.b729feae.eot?#iefix) format("embedded-opentype"),
    url(/static/media/icons.93bf6d29.woff) format("woff"),
    url(/static/media/icons.0c3eb2b7.ttf) format("truetype"),
    url(/static/media/icons.088c9ab4.svg#icons) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\61";
}
.icon-all:before {
  content: "\62";
}
.icon-archive:before {
  content: "\63";
}
.icon-arrow:before {
  content: "\64";
}
.icon-attachment:before {
  content: "\65";
}
.icon-awaiting:before {
  content: "\66";
}
.icon-bell:before {
  content: "\67";
}
.icon-birthday:before {
  content: "\68";
}
.icon-chat:before {
  content: "\69";
}
.icon-chatbot:before {
  content: "\6a";
}
.icon-chess:before {
  content: "\6b";
}
.icon-close:before {
  content: "\6c";
}
.icon-controller:before {
  content: "\6d";
}
.icon-download:before {
  content: "\6e";
}
.icon-error:before {
  content: "\6f";
}
.icon-facebook-c:before {
  content: "\70";
}
.icon-file:before {
  content: "\71";
}
.icon-gear:before {
  content: "\72";
}
.icon-instagram-c:before {
  content: "\74";
}
.icon-linkedin-c:before {
  content: "\75";
}
.icon-location:before {
  content: "\77";
}
.icon-logout:before {
  content: "\79";
}
.icon-mail:before {
  content: "\7a";
}
.icon-more:before {
  content: "\41";
}
.icon-overview:before {
  content: "\42";
}
.icon-phone:before {
  content: "\43";
}
.icon-play:before {
  content: "\44";
}
.icon-plus:before {
  content: "\45";
}
.icon-recorder:before {
  content: "\46";
}
.icon-right:before {
  content: "\47";
}
.icon-send:before {
  content: "\48";
}
.icon-settings:before {
  content: "\49";
}
.icon-smile:before {
  content: "\4a";
}
.icon-sad:before {
  content: "\4b";
}
.icon-spying:before {
  content: "\4c";
}
.icon-star:before {
  content: "\4d";
}
.icon-time:before {
  content: "\4e";
}
.icon-true:before {
  content: "\4f";
}
.icon-twitter-c:before {
  content: "\50";
}
.icon-upload:before {
  content: "\51";
}
.icon-user:before {
  content: "\52";
}
.icon-users:before {
  content: "\53";
}
.icon-welcome:before {
  content: "\54";
}
.icon-home:before {
  content: "\73";
}
.icon-edit:before {
  content: "\76";
}
.icon-like-active:before {
  content: "\78";
}
.icon-arrow-bottom:before {
  content: "\4c";
}
.icon-arrow-left:before {
  content: "\55";
}
.icon-arrow-right:before {
  content: "\56";
}
.icon-arrow-up:before {
  content: "\57";
}
.icon-wifi:before {
  content: "\58";
}
.icon-mentions:before {
  content: "\59";
}
.icon-more-1:before {
  content: "\5a";
}
.icon-forum:before {
  content: "\30";
}
.icon-filter:before {
  content: "\31";
}
.icon-settings-1:before {
  content: "\32";
}
.icon-share:before {
  content: "\33";
}
.icon-summary:before {
  content: "\34";
}
.icon-summary-1:before {
  content: "\35";
}
.icon-globe:before {
  content: "\36";
}
.icon-delete:before {
  content: "\37";
}
.icon-username:before {
  content: "\38";
}
.icon-users-1:before {
  content: "\39";
}
.icon-search:before {
  content: "\21";
}
.icon-password:before {
  content: "\22";
}
.icon-logout-1:before {
  content: "\23";
}
.icon-gears:before {
  content: "\24";
}
.icon-filter-1:before {
  content: "\25";
}
.icon-camera:before {
  content: "\26";
}
.icon-businesswoman:before {
  content: "\27";
}
.icon-password-1:before {
  content: "\28";
}
.icon-manager:before {
  content: "\29";
}
.icon-dashboard:before {
  content: "\2a";
}
.icon-bell-1:before {
  content: "\2b";
}
.icon-feedback:before {
  content: "\2c";
}
.icon-username-1:before {
  content: "\2d";
}
.icon-versions:before {
  content: "\2e";
}
.icon-time-1:before {
  content: "\2f";
}
.icon-tasks:before {
  content: "\3a";
}
.icon-settings-2:before {
  content: "\3b";
}
.icon-requests:before {
  content: "\3c";
}
.icon-post:before {
  content: "\3d";
}
.icon-twitter:before {
  content: "\3e";
}
.icon-star-1:before {
  content: "\3f";
}
.icon-search-1:before {
  content: "\40";
}
.icon-question:before {
  content: "\5b";
}
.icon-posts:before {
  content: "\5d";
}
.icon-phone-1:before {
  content: "\5e";
}
.icon-members:before {
  content: "\5f";
}
.icon-lock:before {
  content: "\60";
}
.icon-location-1:before {
  content: "\7b";
}
.icon-location-2:before {
  content: "\7c";
}
.icon-instagram:before {
  content: "\7d";
}
.icon-info:before {
  content: "\7e";
}
.icon-twitter-c-1:before {
  content: "\5c";
}
.icon-youtube:before {
  content: "\e000";
}
.icon-instagram-c-1:before {
  content: "\e001";
}
.icon-images:before {
  content: "\e002";
}
.icon-home-2:before {
  content: "\e003";
}
.icon-home-1:before {
  content: "\e004";
}
.icon-google-plus:before {
  content: "\e005";
}
.icon-facebook-c-1:before {
  content: "\e006";
}
.icon-facebook:before {
  content: "\e007";
}
.icon-deny:before {
  content: "\e008";
}
.icon-comments:before {
  content: "\e009";
}
.icon-checkmark:before {
  content: "\e00a";
}
.icon-arrow-poly-top:before {
  content: "\e00b";
}
.icon-arrow-poly-right:before {
  content: "\e00c";
}
.icon-arrow-poly-left:before {
  content: "\e00d";
}
.icon-arrow-poly-down:before {
  content: "\e00e";
}
.icon-activities:before {
  content: "\e00f";
}
.icon-calendar:before {
  content: "\e010";
}
.icon-categories:before {
  content: "\e011";
}
.icon-notification:before {
  content: "\e012";
}
.icon-chair:before {
  content: "\e013";
}
.icon-more-2:before {
  content: "\e014";
}
.icon-iconfinder-menu-alt-134216:before {
  content: "\e015";
}
.icon-download-1:before {
  content: "\e016";
}
.icon-analysis:before {
  content: "\e017";
}
.icon-customer:before {
  content: "\e018";
}
.icon-calendar-1:before {
  content: "\e019";
}
.icon-categories-1:before {
  content: "\e01a";
}
.icon-extended-information:before {
  content: "\e01b";
}
.icon-home-3:before {
  content: "\e01c";
}
.icon-information:before {
  content: "\e01d";
}
.icon-logout-2:before {
  content: "\e01e";
}
.icon-notification-1:before {
  content: "\e01f";
}
.icon-type:before {
  content: "\e020";
}
.icon-transactions:before {
  content: "\e021";
}
.icon-reporting:before {
  content: "\e022";
}
.icon-product:before {
  content: "\e023";
}
.icon-orders:before {
  content: "\e024";
}
.icon-filter-2:before {
  content: "\e025";
}
.icon-video:before {
  content: "\e026";
}
.icon-star-o:before {
  content: "\e027";
}
.icon-star-2:before {
  content: "\e028";
}

.icon-bill:before {
    content: "\e029";
}
.icon-invoice:before {
    content: "\e02a";
}
.icon-receipt:before {
    content: "\e02b";
}
.icon-voucher:before {
    content: "\e02c";
}  
.icon-hammer:before {
    content: "\e02d";
}  
*:disabled { cursor: not-allowed!important; }
.input-box { position: relative; max-width: 800px; margin: 10px auto; }
.dark .modal .input-box { opacity: .8; }

.input-box label { position: absolute; font-size: 14px; top: 50%; left: 20px; -webkit-transform: translateY(-50%); transform: translateY(-50%); color: #999; cursor: pointer; -webkit-user-select: none; user-select: none; transition: all .3s ease; }
.rtl .input-box label { right: 20px; left: auto; }
.input-box textarea + label { -webkit-transform: translateY(0); transform: translateY(0); top: 25px; }
.input-box label.active,
.input-box input:focus + label,
.input-box textarea:focus + label { font-size: 10px; top: 30%; }
.input-box textarea:focus + label,
.input-box textarea + label.active { top: 10px; }

.input-box input,
.input-box textarea { line-height: 1; width: 100%; border-radius: 10px; border: none; background: #F7F7F7; outline: none; padding: 25px 20px 15px; cursor: pointer; font-size: 14px; transition: all .3s ease; }
.input-box textarea { line-height: 23px; }
.input-box input:focus,
.input-box textarea:focus { background: #F1F1F1; cursor: unset; }

.dark .input-box input,
.dark .input-box textarea { background: #495568; color: #FFF; }
.dark .modal .input-box input,
.dark .modal .input-box textarea { background: #343b49; opacity: .8; }
.dark .input-box label { color: #828da0 }
.dark .modal .input-box label { opacity: .6; }

.input-box .icon-error { position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); right: 20px; color: tomato; cursor: pointer; }
.textarea-box .icon-error { top: 20px; -webkit-transform: translateY(0); transform: translateY(0); }
.rtl .input-box .icon-error { left: 20px; right: auto; }
.input-box .icon-error::before { background: #FFF; border-radius: 50%; }
.input-box .type-error { background: tomato; }
.input-box .type-error::after { border-left-color: tomato!important; }

.checkbox { position: relative; max-width: 400px; margin: 20px auto; }
.dark .checkbox { color: #FFF; }
.checkbox input { display: none; }
.checkbox i { padding-right: 5px; position: relative; top: 1.8px; cursor: pointer; }
.rtl .checkbox i { padding-left: 5px; padding-right: 0; }
.checkbox label { cursor: pointer; -webkit-user-select: none; user-select: none; }
.checkbox input ~ div { opacity: .2; display: inline-block; transition: all .3s ease; cursor: pointer; font-size: 14px; }
.checkbox input:checked ~ div { opacity: 1; -webkit-animation: scale .4s ease-in-out; animation: scale .4s ease-in-out; }
.checkbox input:disabled ~ div * { cursor: not-allowed!important; }

@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.simple-checkbox { display: inline-block; }
.simple-checkbox input { display: none; }
.simple-checkbox label { display: inline-block; position: relative; width: 20px; height: 20px; background: #FFF; border: 1px solid #DDD; border-radius: 3px; transition: all .3s ease; }
.dark .simple-checkbox label { background: #343b49; border-color: #3a4252; }
.simple-checkbox label i { position: absolute; top: 50%; left: 50%; -webkit-transform: translate( -50%, -50% ) scale(0); transform: translate( -50%, -50% ) scale(0); font-size: 9px; margin-top: 1.5px; color: #FFF; transition: -webkit-transform 500ms cubic-bezier(.26,2,.46,.71); transition: transform 500ms cubic-bezier(.26,2,.46,.71); transition: transform 500ms cubic-bezier(.26,2,.46,.71), -webkit-transform 500ms cubic-bezier(.26,2,.46,.71); }
.simple-checkbox input:checked ~ label { background: #925b97; border-color: #925b97; }
.simple-checkbox input:checked ~ label i { -webkit-transform: translate( -50%, -50% ) scale(1.1); transform: translate( -50%, -50% ) scale(1.1); }


/* Light/Dark Switcher */
.toggle-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .toggle-slot {
    position: relative;
    height: 2em;
    width: 4em;
    border: 1px solid #e4e7ec;
    border-radius: 2em;
    background-color: white;
    box-shadow: 0px 2px 3px #e4e7ec;
    transition: background-color 250ms;
  }
  .dark .toggle-slot { box-shadow: 0px 2px 3px #394150; }
  
  .toggle-checkbox:checked ~ .toggle-slot {
    background-color: #374151;
  }
  
  .toggle-button {
    -webkit-transform: translate(2.35em, 0.3em);
            transform: translate(2.35em, 0.3em);
    position: absolute;
    height: 1.3em;
    width: 1.3em;
    border-radius: 50%;
    background-color: #ffeccf;
    box-shadow: inset 0px 0px 0px 0.15em #ffbb52;
    transition: background-color 250ms, border-color 250ms, -webkit-transform 500ms cubic-bezier(.26,2,.46,.71);
    transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(.26,2,.46,.71);
    transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(.26,2,.46,.71), -webkit-transform 500ms cubic-bezier(.26,2,.46,.71);
  }
  
  .toggle-checkbox:checked ~ .toggle-slot .toggle-button {
    background-color: #485367;
    box-shadow: inset 0px 0px 0px 0.15em white;
    -webkit-transform: translate(0.35em, 0.3em);
            transform: translate(0.35em, 0.3em);
  }
  
  .sun-icon {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    color: #ffbb52;
  }
  
  .sun-icon-wrapper {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    opacity: 1;
    -webkit-transform: translate(0.4em, 0.35em) rotate(15deg);
            transform: translate(0.4em, 0.35em) rotate(15deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    transition: opacity 150ms, -webkit-transform 500ms cubic-bezier(.26,2,.46,.71);
    transition: opacity 150ms, transform 500ms cubic-bezier(.26,2,.46,.71);
    transition: opacity 150ms, transform 500ms cubic-bezier(.26,2,.46,.71), -webkit-transform 500ms cubic-bezier(.26,2,.46,.71);
  }
  
  .toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
    opacity: 0;
    -webkit-transform: translate(0.6em, 0.4em) rotate(0deg);
            transform: translate(0.6em, 0.4em) rotate(0deg);
  }
  
  .moon-icon {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    color: white;
  }
  
  .moon-icon-wrapper {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    opacity: 0;
    -webkit-transform: translate(2.2em, 0.35em) rotate(0deg);
            transform: translate(2.2em, 0.35em) rotate(0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    transition: opacity 150ms, -webkit-transform 500ms cubic-bezier(.26,2.5,.46,.71);
    transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
    transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71), -webkit-transform 500ms cubic-bezier(.26,2.5,.46,.71);
  }
  
  .toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
    opacity: 1;
    -webkit-transform: translate(2.4em, 0.35em) rotate(-15deg);
            transform: translate(2.4em, 0.35em) rotate(-15deg);
  }

  .language-toggle .toggle-button {
      background: #f8f8f8;
      box-shadow: inset 0px 0px 0px 0.15em #374151;
  }
  .dark .language-toggle .toggle-button {
    background-color: #485367!important;
    box-shadow: inset 0px 0px 0px 0.15em white!important;
  }
  .language-toggle .toggle-checkbox:checked ~ .toggle-slot .toggle-button {
    background: #f8f8f8;
    box-shadow: inset 0px 0px 0px 0.15em #374151;
    -webkit-transform: translate(0.35em, 0.3em);
            transform: translate(0.35em, 0.3em);
  }
  .language-toggle .toggle-checkbox:checked ~ .toggle-slot {
    background-color: #FFF;
  }
  .dark .language-toggle .toggle-slot {
    background-color: #374151!important;
  }
  .language-toggle .en {
      position: absolute;
      top: 50%;
      left: 14px;
      font-size: 13px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      opacity: 1;
      -webkit-user-select: none;
              user-select: none;
      font-weight: bold;
      color: #374151;
      transition: opacity 150ms, -webkit-transform 500ms cubic-bezier(.26,2.5,.46,.71);
      transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
      transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71), -webkit-transform 500ms cubic-bezier(.26,2.5,.46,.71);
  }
  .language-toggle .toggle-checkbox:checked ~ .toggle-slot .en {
      -webkit-transform: translate(1em, -50%);
              transform: translate(1em, -50%);
      opacity: 0;
  }
  .language-toggle .ar {
    position: absolute;
    top: 50%;
    right: 7px;
    font-size: 12px;
    -webkit-transform: translate(-1em, -50%);
            transform: translate(-1em, -50%);
    opacity: 0;
    -webkit-user-select: none;
            user-select: none;
    font-weight: bold;
    color: #374151;
    margin-top: -2px;
    transition: opacity 150ms, -webkit-transform 500ms cubic-bezier(.26,2.5,.46,.71);
    transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
    transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71), -webkit-transform 500ms cubic-bezier(.26,2.5,.46,.71);
  }
  .language-toggle .toggle-checkbox:checked ~ .toggle-slot .ar {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 1;
  }
  .dark .language-toggle .en,
  .dark .language-toggle .ar { color: #FFF }


/* Select Field */
form .react-select { margin: 10px 0; text-align: left; }
.rtl form .react-select { text-align: right; }
form .react-select .react-select__control { height: 55px; border-radius: 10px; border: none!important; background: #F7F7F7; font-size: 14px; padding: 0 10px; }
form .react-select.white .react-select__control { background: #FFF; border: 1px solid #EEE!important; }
.react-select .react-select__control { border-radius: 3px; cursor: pointer; }
.react-select .react-select__control,
.react-select .react-select__control:hover,
.react-select .react-select__control--is-focused,
.react-select .react-select__control--menu-is-open  { line-height: 25px; border: 1px solid #EEE; box-shadow: none; }
.dark .react-select .react-select__control { background: #343b49; opacity: .7; }
.dark .react-select .react-select__control,
.dark .react-select .react-select__control:hover,
.dark .react-select .react-select__control--is-focused,
.dark .react-select .react-select__control--menu-is-open { border-color: #434b5c; }
.dark .react-select .react-select__control .react-select__single-value { color: #FFF; }
.dark .react-select .react-select__control .react-select__indicator { color: #FFF; }
.dark .react-select .react-select__control:not(.react-select__control--menu-is-open) .react-select__indicator,
.dark .react-select .react-select__control .react-select__placeholder { color: #58637c; }
.dark .react-select .react-select__control:not(.react-select__control--menu-is-open) .react-select__indicator-separator { background: #58637c; }

.react-select .react-select__menu { line-height: 30px; margin: 0; border: none; box-shadow: 0 0 5px rgba(0, 0, 0, .05); border-radius: 0; }
.dark .react-select .react-select__menu { background: #434b5c; }
.react-select .react-select__menu-list { padding: 10px; }
.react-select .react-select__menu-list .react-select__option { border-radius: 3px; cursor: pointer; background: transparent!important; transition: all .3s ease; }
.dark .react-select .react-select__menu-list .react-select__option { color: #FFF; opacity: .6; }
.react-select .react-select__menu-list .react-select__option--is-focused { background: #F7F7F7!important; }
.dark .react-select .react-select__menu-list .react-select__option--is-focused { background: #64708b!important; }
.dark .react-select .react-select__menu-list .react-select__option--is-selected,
.react-select .react-select__menu-list .react-select__option--is-selected { background: #925b97!important; opacity: 1; }

.select-holder { position: relative; }
.select-holder .icon-error { position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); right: 20px; color: tomato; cursor: pointer; }
.rtl .select-holder .icon-error { left: 20px; right: auto; }
.select-holder .icon-error::before { background: #FFF; border-radius: 50%; }
.select-holder .type-error { background: tomato; }
.select-holder .type-error::after { border-left-color: tomato!important; }

/* To fix a weird issue! */
.add-brand .__react_component_tooltip  { transition: none; }


/* Tags */
.tags-box { margin: 10px 0; position: relative; }
.tags-box tags { padding: 11.6px 10px; border-radius: 10px; background: #F7F7F7; border: none; text-align: left; font-size: 13px; }
.dark .tags-box tags { background: #2c333f; }
.dark .tags-box tags .tagify__input:empty::before,
.dark .tags-box tags .tagify__input:focus:empty::before { color: #828da0; opacity: .6; }
.dark .tags-box tags .tagify__tag--editable .tagify__tag-text { color: #FFF; }
.dark .tags-box tags .tagify__tag__removeBtn:hover+div>span { opacity: 1; color: #FFF; }
.tags-box .icon-error { position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); right: 20px; color: tomato; cursor: pointer; }
.rtl .tags-box .icon-error { left: 20px; right: auto; }
.tags-box .icon-error::before { background: #FFF; border-radius: 50%; }
.tags-box .type-error { background: tomato; }
.tags-box .type-error::after { border-left-color: tomato!important; }


/* Radio button */
.radio-button { display: inline-block; margin: 10px 0; }
.radio-button [type="radio"]:checked,
.radio-button [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.rtl .radio-button [type="radio"]:checked,
.rtl .radio-button [type="radio"]:not(:checked) { left: auto; right: -9999px; }
.radio-button [type="radio"]:checked + label,
.radio-button [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.rtl .radio-button [type="radio"]:checked + label,
.rtl .radio-button [type="radio"]:not(:checked) + label {
    padding-left: 0;
    padding-right: 28px;
}
.radio-button [type="radio"]:checked + label:before,
.radio-button [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.rtl .radio-button [type="radio"]:checked + label:before,
.rtl .radio-button [type="radio"]:not(:checked) + label:before { left: auto; right: 0; }
.radio-button [type="radio"]:checked + label:after,
.radio-button [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #925b97;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.rtl .radio-button [type="radio"]:checked + label:after,
.rtl .radio-button [type="radio"]:not(:checked) + label:after { left: auto; right: 4px; }
.radio-button [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radio-button [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/* Tagify rtl */
.rtl .tags-box tags { text-align: right; }
.rtl .tagify__tag { margin: 5px 5px 5px 0; }
.rtl .tagify__tag__removeBtn { margin-left: 4.66667px; margin-right: -4.66667px; }
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

/* Ellipsis Loader */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #925b97;
	-webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
	        animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	-webkit-animation: lds-ellipsis1 0.6s infinite;
	        animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	-webkit-animation: lds-ellipsis2 0.6s infinite;
	        animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	-webkit-animation: lds-ellipsis2 0.6s infinite;
	        animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	-webkit-animation: lds-ellipsis3 0.6s infinite;
	        animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes lds-ellipsis1 {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes lds-ellipsis3 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@-webkit-keyframes lds-ellipsis2 {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(24px, 0);
		        transform: translate(24px, 0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(24px, 0);
		        transform: translate(24px, 0);
	}
}

/* Whitebox loader */
.whitebox-loader { position: absolute; width: 100%; height: 100%; background: rgba(255, 255, 255, .5); top: 0; left: 0; z-index: 10; }
.dark .whitebox-loader { background: #1a202986; }


/* Success mark */
.success-holder { position: absolute; width: 100%; height: 100%; background: rgba(255, 255, 255, 0); top: 0; left: 0; z-index: 10; }
.modal .success-holder { -webkit-animation: fadeBgIn .5s ease forwards; animation: fadeBgIn .5s ease forwards; }
.dark .modal .success-holder { -webkit-animation: fadeBgInDark .5s ease forwards; animation: fadeBgInDark .5s ease forwards; }
.success-checkmark {
	width: 80px;
	height: 115px;
    margin: -20px auto 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.success-checkmark .check-icon {
	width: 80px;
	height: 80px;
	position: relative;
	border-radius: 50%;
	box-sizing: content-box;
	border: 4px solid #925b97;
}

.success-checkmark .check-icon::before {
	top: 3px;
	left: -2px;
	width: 30px;
	-webkit-transform-origin: 100% 50%;
	        transform-origin: 100% 50%;
	border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
	top: 0;
	left: 30px;
	width: 60px;
	-webkit-transform-origin: 0 50%;
	        transform-origin: 0 50%;
	border-radius: 0 100px 100px 0;
	-webkit-animation: rotate-circle 4.25s ease-in;
	        animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
	content: '';
	height: 100px;
	position: absolute;
	background: #FFFFFF;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
.dark .success-checkmark .check-icon::before,
.dark .success-checkmark .check-icon::after {
    background: #323b4b;
}

.success-checkmark .check-icon .icon-line {
	height: 5px;
	background-color: #925b97;
	display: block;
	border-radius: 2px;
	position: absolute;
	z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
	top: 46px;
	left: 14px;
	width: 25px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	-webkit-animation: icon-line-tip 0.75s;
	        animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
	top: 38px;
	right: 8px;
	width: 47px;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	-webkit-animation: icon-line-long 0.75s;
	        animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
	top: -4px;
	left: -4px;
	z-index: 10;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: absolute;
	box-sizing: content-box;
	border: 4px solid rgba(211, 53, 114, 0);
}

.success-checkmark .check-icon .icon-fix {
	top: 8px;
	width: 5px;
	left: 26px;
	z-index: 1;
	height: 85px;
	position: absolute;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	background-color: #FFFFFF;
}
.dark .success-checkmark .check-icon .icon-fix {
    background-color: #323b4b;
}

@-webkit-keyframes rotate-circle {
	0% {
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
	5% {
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
	12% {
		-webkit-transform: rotate(-405deg);
		        transform: rotate(-405deg);
	}
	100% {
		-webkit-transform: rotate(-405deg);
		        transform: rotate(-405deg);
	}
}

@keyframes rotate-circle {
	0% {
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
	5% {
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
	12% {
		-webkit-transform: rotate(-405deg);
		        transform: rotate(-405deg);
	}
	100% {
		-webkit-transform: rotate(-405deg);
		        transform: rotate(-405deg);
	}
}

@-webkit-keyframes icon-line-tip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@keyframes icon-line-tip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@-webkit-keyframes icon-line-long {
	0% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

@keyframes icon-line-long {
	0% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

@-webkit-keyframes fadeBgIn {
    0% {
        background: rgba(255, 255, 255, 0);
    }
    100% {
        background: rgba(255, 255, 255, 1);
    }
}

@keyframes fadeBgIn {
    0% {
        background: rgba(255, 255, 255, 0);
    }
    100% {
        background: rgba(255, 255, 255, 1);
    }
}
@-webkit-keyframes fadeBgInDark {
    0% {
        background: #323b4b00;
    }
    100% {
        background: #323b4b;
    }
}
@keyframes fadeBgInDark {
    0% {
        background: #323b4b00;
    }
    100% {
        background: #323b4b;
    }
}


/* Colorfull loader */
.cssload-dots {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	outline: 1px solid rgb(35, 174, 230);
	filter: url(#goo);
		-o-filter: url(#goo);
		-ms-filter: url(#goo);
		-webkit-filter: url(#goo);
		-moz-filter: url(#goo);
}

.cssload-dot {
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
}
.cssload-dot:before {
	content: "";
	width: 34px;
	height: 34px;
	/* border-radius: 49px; */
	background: rgb(26,188,156);
	position: absolute;
	left: 50%;
	transform: translateY(0);
		-o-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
	margin-left: -17.5px;
	margin-top: -17.5px;
}



.cssload-dot:nth-child(5):before {
	z-index: 100;
	width: 44.5px;
	height: 44.5px;
	margin-left: -21.75px;
	margin-top: -21.75px;
	animation: cssload-dot-colors 4.6s ease infinite;
		-o-animation: cssload-dot-colors 4.6s ease infinite;
		-ms-animation: cssload-dot-colors 4.6s ease infinite;
		-webkit-animation: cssload-dot-colors 4.6s ease infinite;
		-moz-animation: cssload-dot-colors 4.6s ease infinite;
}


.cssload-dot:nth-child(1) {
	animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-o-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-ms-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-webkit-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-moz-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
}
.cssload-dot:nth-child(1):before {
	background-color: rgb(35, 174, 230);
	animation: cssload-dot-move 4.6s 0s ease infinite;
		-o-animation: cssload-dot-move 4.6s 0s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 0s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 0s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 0s ease infinite;
}

.cssload-dot:nth-child(2) {
	animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-o-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-ms-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-webkit-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-moz-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
}
.cssload-dot:nth-child(2):before {
	background-color: rgb(58,230,230);
	animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-o-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 1.15s ease infinite;
}

.cssload-dot:nth-child(3) {
	animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-o-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-ms-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-webkit-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-moz-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
}
.cssload-dot:nth-child(3):before {
	background-color: rgb(58,230,230);
	animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-o-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 2.3s ease infinite;
}

.cssload-dot:nth-child(4) {
	animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-o-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-ms-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-webkit-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-moz-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
}
.cssload-dot:nth-child(4):before {
	background-color: rgb(26,188,156);
	animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-o-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 3.45s ease infinite;
}

@keyframes cssload-dot-move {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	18%, 22% {
		-webkit-transform: translateY(-68px);
		        transform: translateY(-68px);
	}
	40%, 100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes cssload-dot-move {
	0% {
		-webkit-transform: translateY(0);
	}
	18%, 22% {
		-webkit-transform: translateY(-68px);
	}
	40%, 100% {
		-webkit-transform: translateY(0);
	}
}

@keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(58,230,230);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@-webkit-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(58,230,230);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@keyframes cssload-dot-rotate-1 {
	0% {
		-webkit-transform: rotate(-105deg);
		        transform: rotate(-105deg);
	}
	100% {
		-webkit-transform: rotate(270deg);
		        transform: rotate(270deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-1 {
	0% {
		-webkit-transform: rotate(-105deg);
	}
	100% {
		-webkit-transform: rotate(270deg);
	}
}

@keyframes cssload-dot-rotate-2 {
	0% {
		-webkit-transform: rotate(165deg);
		        transform: rotate(165deg);
	}
	100% {
		-webkit-transform: rotate(540deg);
		        transform: rotate(540deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-2 {
	0% {
		-webkit-transform: rotate(165deg);
	}
	100% {
		-webkit-transform: rotate(540deg);
	}
}

@keyframes cssload-dot-rotate-3 {
	0% {
		-webkit-transform: rotate(435deg);
		        transform: rotate(435deg);
	}
	100% {
		-webkit-transform: rotate(810deg);
		        transform: rotate(810deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-3 {
	0% {
		-webkit-transform: rotate(435deg);
	}
	100% {
		-webkit-transform: rotate(810deg);
	}
}

@keyframes cssload-dot-rotate-4 {
	0% {
		-webkit-transform: rotate(705deg);
		        transform: rotate(705deg);
	}
	100% {
		-webkit-transform: rotate(1080deg);
		        transform: rotate(1080deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-4 {
	0% {
		-webkit-transform: rotate(705deg);
	}
	100% {
		-webkit-transform: rotate(1080deg);
	}
}
#modal-container { position: fixed; display: table; height: 100%; width: 100%; top: 0; left: 0; -webkit-transform: scale(1); transform: scale(1); z-index: 10000000; transition: all .5s ease; }
#modal-container .modal-background { display: table-cell; text-align: center; vertical-align: middle; background: rgba(0, 0, 0, 0); -webkit-animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
#modal-container .modal-background .modal { display: inline-block; border-radius: 3px; font-weight: 300; position: relative; background-color: transparent; -webkit-animation: modalFadeIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: modalFadeIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; max-height: 90vh; overflow-y: hidden; }
.rtl #modal-container .modal-background .modal .modal-scroller > div { margin-right: 0!important; margin-left: -17px!important; }
.dark #modal-container .modal-background .modal { -webkit-animation: modalFadeInDark 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: modalFadeInDark 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
#modal-container .modal-background .modal .modal-content {     margin-right: 10px; padding: 50px; opacity: 0; position: relative; -webkit-animation: modalContentFadeIn 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: modalContentFadeIn 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; z-index: 1; }
.dark #modal-container .modal-background .modal .modal-content { color: #FFF; }
#modal-container .modal-background .modal .modal-svg { position: absolute; top: 0; left: 0; height: 100%; width: 100%; border-radius: 3px; pointer-events: none; }
#modal-container .modal-background .modal .modal-svg rect { stroke: #fff; stroke-width: 2px; width: 100%; height: 100%; }

#modal-container.out { opacity: 0; visibility: hidden; }
#modal-container.out .modal-background { -webkit-animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
#modal-container.out .modal-background .modal { -webkit-animation: modalFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: modalFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
.dark #modal-container.out .modal-background .modal { -webkit-animation: modalFadeOutDark 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: modalFadeOutDark 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
#modal-container.out .modal-background .modal .modal-content { -webkit-animation: modalContentFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; animation: modalContentFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }

#modal-container .icon-close {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 20px;
	cursor: pointer;
	font-size: 20px;
	z-index: 2000000;
}

@-webkit-keyframes fadeIn {
	0% {
		background: rgba(0, 0, 0, 0);
	}
	100% {
		background: rgba(0, 0, 0, 0.7);
	}
}

@keyframes fadeIn {
	0% {
		background: rgba(0, 0, 0, 0);
	}
	100% {
		background: rgba(0, 0, 0, 0.7);
	}
}

@-webkit-keyframes fadeOut {
	0% {
		background: rgba(0, 0, 0, 0.7);
	}
	100% {
		background: rgba(0, 0, 0, 0);
	}
}

@keyframes fadeOut {
	0% {
		background: rgba(0, 0, 0, 0.7);
	}
	100% {
		background: rgba(0, 0, 0, 0);
	}
}

@-webkit-keyframes modalFadeIn {
	0% {
		background-color: transparent;
	}
	100% {
        background-color: white;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
        overflow-y: auto;
	}
}

@keyframes modalFadeIn {
	0% {
		background-color: transparent;
	}
	100% {
        background-color: white;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
        overflow-y: auto;
	}
}

@-webkit-keyframes modalFadeOut {
	0% {
        background-color: white;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
        overflow-y: auto;
	}
	100% {
        background-color: transparent;
        overflow-y: auto;
	}
}

@keyframes modalFadeOut {
	0% {
        background-color: white;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .1);
        overflow-y: auto;
	}
	100% {
        background-color: transparent;
        overflow-y: auto;
	}
}

@-webkit-keyframes modalFadeInDark {
	0% {
		background-color: transparent;
	}
	100% {
        background-color: #1a2029;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
        overflow-y: auto;
	}
}

@keyframes modalFadeInDark {
	0% {
		background-color: transparent;
	}
	100% {
        background-color: #1a2029;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
        overflow-y: auto;
	}
}

@-webkit-keyframes modalFadeOutDark {
	0% {
        background-color: #1a2029;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
        overflow-y: auto;
	}
	100% {
        background-color: transparent;
        overflow-y: auto;
	}
}

@keyframes modalFadeOutDark {
	0% {
        background-color: #1a2029;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
        overflow-y: auto;
	}
	100% {
        background-color: transparent;
        overflow-y: auto;
	}
}

@-webkit-keyframes modalContentFadeIn {
	0% {
		opacity: 0;
		top: -20px;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes modalContentFadeIn {
	0% {
		opacity: 0;
		top: -20px;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}

@-webkit-keyframes modalContentFadeOut {
	0% {
		opacity: 1;
		top: 0px;
	}
	100% {
		opacity: 0;
		top: -20px;
	}
}

@keyframes modalContentFadeOut {
	0% {
		opacity: 1;
		top: 0px;
	}
	100% {
		opacity: 0;
		top: -20px;
	}
}
.alert { background: coral; color: #FFF; padding: 15px 20px; border-radius: 8px; font-size: 13px; cursor: pointer; -webkit-transform: scale(.9); transform: scale(.9); opacity: 0; -webkit-animation: .5s alertScale cubic-bezier(.26,2.5,.46,.71) forwards; animation: .5s alertScale cubic-bezier(.26,2.5,.46,.71) forwards; }
.alert.error { background: #e55039; }
.alert.success { background: #27ae60; }
.alert.info { background: #3498db; }

@-webkit-keyframes alertScale {
	0% {
        -webkit-transform: scale(.5);
                transform: scale(.5);
        opacity: 0;
	}
	100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
	}
}

@keyframes alertScale {
	0% {
        -webkit-transform: scale(.5);
                transform: scale(.5);
        opacity: 0;
	}
	100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
	}
}
.login-form .input-box { margin: 20px auto; }
.login-form .button { height: 50px; position: relative; }
.login-form .button.scale { -webkit-transform: scale(18); transform: scale(18); background: #FFF; }
.dark .login-form .button.scale { background: #323b4b; }
.dark .login-form .button.scale .lds-ripple div { border-color: #323b4b; }
.login-form .button .lds-ripple { position: absolute; top: 50%; left: 50%; -webkit-transform: translate( -50%, -50% ) scale(.5); transform: translate( -50%, -50% ) scale(.5); margin-left: 2.5px; margin-top: 1px; }
.login-page { width: 100vw; height: 97vh; background-image: url(/static/media/light.6de6f136.svg); background-size: cover; }
.dark .login-page { background-image: url(/static/media/dark.4ae5d5a5.svg); }
.login-page .layout { position: fixed; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 100%; max-width: 400px; transition: all .3s ease; }
.login-page .layout .form-holder { background: #FFF; padding: 50px; border-radius: 15px; box-shadow: 0 0 5px rgba(0, 0, 0, .1); overflow: hidden; width: 100%; }
.login-page .layout .form-holder .logo { margin: 0 0 20px; width: 150px; position: relative; left: -10px; }
.dark .login-page .layout .form-holder { background: #323b4b; }

.login-page .switch-actions { margin: 0 auto; width: -webkit-fit-content; width: -moz-fit-content; width: fit-content; margin-top: 30px; direction: ltr; }
.login-page .switch-actions label { margin: 0 5px; direction: ltr; }
.login-page .switch-actions label * { direction: ltr; }
.dashboard-page { width: 100vw; min-height: 100vh; background: #F5F5F5; }
.dark .dashboard-page { background: #222730; }
.main-side { margin-left: 80px; background: #FFF; box-shadow: -1px 0 3px rgba(0, 0, 0, .1); min-height: 100vh; border-radius: 35px 0 0 35px; overflow: hidden; position: relative; }
.dark .main-side { background: #1a2029; }
.rtl .main-side { margin-left: 0; margin-right: 80px; border-radius: 0 35px 35px 0; }
.main-side > * { padding-left: 120px; }
.rtl .main-side > * { padding-left: 0; padding-right: 120px; }
.main-side .content { padding-top: 50px; padding-right: 40px; position: relative; min-height: 85vh; }
.main-side .content > .whitebox-loader { position: fixed; z-index: 100; top: 75px; margin-left: 140px; }
.main-side .content > .whitebox-loader .center { margin-top: -57.5px; margin-left: -100px; }
.rtl .main-side .content > .whitebox-loader { margin-right: 140px; margin-left: 0; }
.rtl .main-side .content { padding-left: 40px; padding-right: 120px; }

.weight { border: 1px solid #777; border-radius: 5px; margin: 20px 0; padding: 10px; position: relative; }
.weight > label { position: absolute; top: 0; left: 10px; background: #F7F7F7; padding: 0 10px; -webkit-transform: translatey(-50%); transform: translatey(-50%); font-size: 13px; }
.rtl .weight > label { left: auto; right: 10px; }

button[disabled]{
    opacity: .3;
}
.side-nav { background: #925b97; width: 120px; text-align: center; border-radius: 25px; overflow: hidden; position: fixed; top: 53%; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 20px; z-index: 11; }
/* RTL scrollbar */
.rtl .side-nav > div > div { margin-right: 0!important; margin-left: -17px; }
.rtl .side-nav { right: 20px; left: auto; }
.side-nav .active-box { display: none; position: absolute; width: 100%; height: 90px; background: #34dab9; top: 0; opacity: .5; transition: top 600ms cubic-bezier(.26,2,.46,.71) }
.side-nav ul { list-style: none; padding: 0; margin: 0; }
.side-nav ul li { height: 90px; color: #FFF; position: relative; cursor: pointer; opacity: .7; transition: all .3s ease; }
.side-nav ul li a { color: #FFF; display: block; height: 90px; }
.side-nav ul li.active,
.side-nav ul li:hover { opacity: 1; -webkit-transform: scale(1.1 ); transform: scale(1.1 ); }
.side-nav ul li span { line-height: 18px; position: absolute; top: 50%; left: 50%; width: 100%; -webkit-transform: translate( -50%, -50% ); transform: translate( -50%, -50% ); font-size: 12px; padding: 0 15px; }
.side-nav ul li i { display: block; font-size: 25px; margin-bottom: 5px; }

.side-nav ul ul { background: #793b7e; padding: 0 20px; }
.side-nav ul ul i { display: none; }
.side-nav ul ul li { opacity: .6; }
.side-nav ul ul li.active,
.side-nav ul ul li:hover { -webkit-transform: scale(1.15); transform: scale(1.15); }
.side-nav ul ul li,
.side-nav ul ul li a { height: 55px; }
.side-nav ul ul li span { line-height: 17px; }
/* .side-nav ul ul li span::before { content: ""; position: absolute; width: 0; height: 2px; background: #FFF; bottom: -8px; left: 50%; margin-left: -10px; transition: all .3s ease; }
.side-nav ul ul li.active span::before { width: 20px; } */

.top-nav { padding: 10px 70px 10px 120px; box-shadow: 0 0 5px rgba(0, 0, 0, .1); }
.rtl .top-nav { padding-left: 70px!important; padding-right: 120px; }
.top-nav .logo { width: auto; height: 60px; }
.top-nav .actions { float: right; }
.rtl .top-nav .actions { float: left; }
.top-nav .actions .switchers { display: inline-block; line-height: 74px; direction: ltr; }
.top-nav .actions .switchers * { direction: ltr; }
.top-nav .icon-logout,
.top-nav .icon-username-1 { width: 32px; height: 32px; line-height: 35px; position: relative; top: 11px; text-align: center; background: #FFF; box-shadow: 1px 1px 5px rgba(0, 0, 0, .2); border-radius: 50%; font-size: 13px; cursor: pointer; color: #333; float: right; margin-left: 10px; overflow: hidden; transition: -webkit-transform .3s ease; transition: transform .3s ease; transition: transform .3s ease, -webkit-transform .3s ease; }
.top-nav .userinfo { float: right; cursor: pointer; }
.top-nav .userinfo span { float: right; position: relative; top: 11px; margin: 0 0 0 10px; font-size: 12px; line-height: 17px; }
.rtl .top-nav .icon-logout,
.rtl .top-nav .icon-username-1 { float: left; margin-left: 0; margin-right: 10px; position: relative; }
.rtl .top-nav .userinfo { float: left; }
.rtl .top-nav .userinfo span { float: left; margin: 0 10px 0 0; }
.dark .top-nav .userinfo span { color: #FFF; }
.top-nav .icon-logout::before { position: relative; left: 1px; }
.top-nav .icon-logout::after { content: ''; position: absolute; width: 50px; height: 15px; background: #925b97; opacity: .3; left: -25px; top: -10px; -webkit-transform: rotate(-45deg); transform: rotate(-45deg); transition: all .2s ease; }
.top-nav .icon-logout:hover::after { left: 5px; top: 30px; }
.top-nav .icon-logout:hover { -webkit-transform: scale(1.1); transform: scale(1.1); }


/* Reports list */
.reports-list { position: relative; display: inline-block; margin-right: 20px; top: -10px; color: #222; }
.rtl .reports-list { margin-right: 0; margin-left: 20px; }
.dark .reports-list { color: #FFF; }
.reports-list ul { position: absolute; background: #FFF; border-radius: 5px; box-shadow: 1px 2px 4px rgba(0, 0, 0, .1); z-index: 1000000; padding: 10px 20px; margin: 0; top: 40px; list-style: none; opacity: 0; visibility: hidden; -webkit-transform: scale(.95) translateX(-40%); transform: scale(.95) translateX(-40%); width: -webkit-max-content; width: max-content; left: 0; transition: all .3s ease .5s; }
.reports-list span { cursor: pointer; }
.reports-list:hover ul { opacity: 1; visibility: visible; -webkit-transform: scale(1) translateX(-40%); transform: scale(1) translateX(-40%); transition-delay: 0s; }
.reports-list ul li { line-height: 45px; padding: 0 20px; }
.reports-list ul li a { color: #333; text-decoration: none; display: block; transition: all .3s ease; cursor: pointer; }
.reports-list ul li a:hover { color: #925b97; }
.details-modal-title { margin: 0 0 30px; font-size: 24px; color: #333; }
.dark .details-modal-title { color: #FFF; }
.details-table { width: 500px; max-width: 90vw; border-spacing: 0; border-collapse: unset; border-radius: 10px; overflow: hidden; box-shadow: 0 0 5px rgba(0, 0, 0, .08); margin: 0 auto; }
.details-table tr { line-height: 40px; }
.details-table tr td { border-top: 1px solid #FAFAFA; font-size: 13px; line-height: 23px; padding: 10px 20px; text-align: left; }
.dark .details-table tr td { border-color: #1a2029; }
.rtl .details-table tr td { text-align: right; }
.details-table tr:first-of-type td { border-top: none; }
.details-table tr td:nth-of-type(2n - 1) { background: #F9F9F9; font-weight: bold; color: #555; max-width: 160px; }
.dark .details-table tr td:first-of-type { background: #373e4b; color: #7a8496; }
.details-table tr td:nth-of-type(n + 2) { background: #F9F9F9; color: #111; }
.details-table tr td span.label { opacity: .5; }
.dark .details-table tr td:nth-of-type(n + 2) { background: #282e38; color: #FFF; }
.details-table tr td img { max-width: 100px; display: block; margin: 20px 0; }
.details-table tr td ul { padding: 0; list-style: none; padding-left: 15px; }
.rtl .details-table tr td ul { padding-left: 0; padding-right: 15px; }
.details-table tr td ul li { padding: 5px 0; position: relative; }
.details-table tr td ul li::before { content: "• "; color: #925b97; font-size: 30px; position: absolute; top: 50%; left: -15px; -webkit-transform: translateY(-50%); transform: translateY(-50%); }
.rtl .details-table tr td ul li::before { right: 0; left: auto; }

.details-table tr td:nth-of-type(even) { background: #EEE; }

.details-table .tags { padding: 0; line-height: normal; }
.details-table .tags li { margin-bottom: 5px; }
.dashboard-table { padding-right: 30px; margin-bottom: 20px; }
.rtl .dashboard-table { padding-left: 30px; padding-right: 0; }
.dashboard-table table { width: 100%; text-align: left; border-collapse: unset; border-spacing: 0 10px; position: relative; }
.rtl .dashboard-table table { text-align: right; }

/* Head */
.dashboard-table table thead tr { line-height: 30px; min-height: 50px; }
.dashboard-table table thead tr th { font-weight: medium; font-size: 13px; padding: 0 10px; color: #777; }
.dark .dashboard-table table thead tr th { color: #495161; }


/* Body */
.dashboard-table table tbody tr { line-height: 60px; position: relative; -webkit-transform: translateX(0); transform: translateX(0); transition: all .3s ease; }
.dashboard-table table tbody tr.active { -webkit-transform: translateX(10px); transform: translateX(10px); }
.rtl .dashboard-table table tbody tr.active { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
.dashboard-table table tbody tr::after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none; background: #FAFAFA; border-radius: 5px; transition: all .3s ease; }
.dark .dashboard-table table tbody tr::after { background: #222730; }
.dashboard-table table tbody tr:hover::after,
.dashboard-table table tbody tr.active::after { background: #F5F5F5; }
.dark .dashboard-table table tbody tr:hover::after,
.dark .dashboard-table table tbody tr.active::after { background: #292f3a; }
.dashboard-table table tbody tr td { position: relative; z-index: 1; font-size: 13px; padding: 15px 10px; line-height: 22px; }
.dark .dashboard-table table tbody tr td { color: #DDD; }
.dashboard-table table tbody tr td:first-of-type label { top: 5px; -webkit-transform: scale(.8); transform: scale(.8); }
.dashboard-table table tbody tr td:first-of-type label,
.dashboard-table table tbody tr td input.select-row { pointer-events: none; }
.dashboard-table table tbody tr td .show-on-hover { opacity: 0; visibility: hidden; text-align: center; transition: all .3s ease; }
.dashboard-table table tbody tr:hover td .show-on-hover { opacity: 1; visibility: visible; }
.dashboard-table table tbody tr td .react-select { width: 180px; }

.dashboard-table table tbody tr td .show-on-hover i { margin: 0 5px; width: 30px; height: 30px; line-height: 32px; text-align: center; background: #FFF; border-radius: 3px; display: inline-block;  cursor: pointer; transition: all .3s ease; }
.dashboard-table table tbody tr td .show-on-hover i:hover { -webkit-transform: scale(1.2); transform: scale(1.2); }
.dark .dashboard-table table tbody tr td .show-on-hover i { background: #343b49; }
.dashboard-table table tbody tr td .show-on-hover i.icon-edit { color: #0984E3; }
.dashboard-table table tbody tr td .show-on-hover i.icon-delete { color: #FF6B6B; }

.dashboard-table table tbody tr td .reports a { text-decoration: none; }
.dashboard-table table tbody tr td .reports i { font-size: 14px; margin: 0 5px; width: 30px; height: 30px; line-height: 32px; text-align: center; background: #FFF; border-radius: 3px; display: inline-block; color: #333; transition: all .3s ease; }
.dark .dashboard-table table tbody tr td .reports i { color: #FFF; background: #343b49; }
.dashboard-table table tbody tr td .reports i:hover { -webkit-transform: scale(1.2); transform: scale(1.2); color: #925b97; }
.dashboard-table table tbody tr td .reports i.icon-bill { font-size: 15px; }

.dashboard-table table tbody tr td .icon-star-o,
.dashboard-table table tbody tr td .icon-star-2 { font-size: 17px; margin-left: 17px; display: inline-block; color: #925b97; transition: all .3s ease; }
.dashboard-table table tbody tr td .icon-star-o:hover,
.dashboard-table table tbody tr td .icon-star-2:hover { -webkit-transform: scale(1.3); transform: scale(1.3); }

.dashboard-table table tbody tr.table-loader { height: 60px; }
.dashboard-table table tbody tr.table-loader::after { display: none; }
.dashboard-table table tbody tr.table-loader .lds-ellipsis { -webkit-transform: scale(.7); transform: scale(.7); margin-top: 20px; }

.dashboard-table table tr.no-items { -webkit-transform: translate(0); transform: translate(0); line-height: 55px; }
.dashboard-table table tr.no-items td { position: relative; font-size: 14px; color: #555; }
.dashboard-table table tr.no-items::before { content: ""; position: absolute; width: 100%; top: 0; left: 0; background: #F5F5F5; height: 60px; border-radius: 10px; }
.dark .dashboard-table table tr.no-items td { color: #FFF; }
.dark .dashboard-table table tr.no-items::before { background: #292f3a; }

/* Tags */
.tags { list-style: none; padding: 0; margin: 0; max-width: 300px; line-height: 40px; }
.tags li { display: inline-block; background: #EAEAEA; line-height: 35px; padding: 0 10px; border-radius: 5px; margin-right: 5px; cursor: pointer; transition: all .3s ease; }
.dark .tags li { background: #292f3a; }
.dark .dashboard-table table tbody tr:hover .tags li { background: #343b49; }
.tags li:hover { -webkit-transform: scale(1.05); transform: scale(1.05); background: #925b97; color: #FFF; }

/* Print table */
.print-container { padding: 10px 30px; }
table.print-table { margin: 0 auto 0; width: 100%; }
table.print-table,
table.print-table th,
table.print-table td { border-collapse: collapse; border: 1px solid black; text-align: left; }
table.print-table { page-break-inside: avoid; }
table.print-table th,
table.print-table td { padding: 5px 10px; }
table.print-table th { font-weight: bold; }
.rtl table.print-table th,
.rtl table.print-table td { text-align: right; }
.add-profile h2 { text-align: left; }
.rtl .add-profile h2 { text-align: right; }
.profile-questions { text-align: left; padding-bottom: 50px; }
.rtl .profile-questions {text-align: right;  }
.profile-questions header { padding: 20px; font-weight: bold; }
.profile-questions li { padding: 10px 20px; position: relative; }
.profile-questions li span.value { position: absolute; top: 50%; right: 20px; -webkit-transform: translateY(-50%); transform: translateY(-50%); color: #555; }
.rtl .profile-questions li span.value { right: auto; left: 20px; }
.profile-questions li span.value.active { color: #925b97; font-weight: bold; }

.profile-questions .save {
    position: fixed;
    background: #FFF;
    box-shadow: 0 -3px 3px rgba(0, 0, 0, .05);
    width: 800px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 10px 50px;
    bottom: 5vh;
    border-radius: 5px;
}

.avatar_uploader .filepond--list-scroller { margin: 0!important; -webkit-transform: translate(0)!important; transform: translate(0)!important; }
.avatar_uploader .filepond--item { height: 150px!important; width: 150px!important; margin: 0!important; -webkit-transform: translateX(-10px)!important; transform: translateX(-10px)!important; }
.filepond--wrapper * { direction: ltr; }

.filepond--wrapper,
.filepond--drop-label.filepond--drop-label label { cursor: pointer; }
.filepond--file-action-button.filepond--action-abort-item-load { margin-left: -3px!important; }
.doka-image-editor {
    --doka-button-primary--color: red;
    --color-primary: #2990ff;
    --color-primary-dark: #1a80ec;
    --color-primary-text: #fff;
    --color-secondary: #03a9f4;
    --color-secondary-dark: #046bbf;
}

.rtl .doka--root * { direction: ltr; }
.filepond--credits { display: none; }
.action-bar { margin-bottom: 70px; padding-right: 30px; }
.rtl .action-bar { padding-left: 30px; padding-right: 0; }

.action-bar h2 { float: left; color: #444; font-size: 28px; font-weight: normal; margin: 0; padding: 0; line-height: 45px; margin-right: 30px; }
.dark .action-bar h2 { color: #636b7a; }
.rtl .action-bar h2 { float: right; margin-right: 0; margin-left: 30px; }

/* Search */
.action-bar .search { float: left; position: relative; }
.rtl .action-bar .search { float: right; }
.action-bar .search input { width: 200px; line-height: 45px; border-radius: 50px; border: 1px solid #DDD; padding: 0 20px; outline: none; cursor: pointer; transition: all .6s cubic-bezier(.26,2,.46,.71) .2s; }
.dark .action-bar .search input { background: #292f3a; border-color: #343b49; color: #FFF; }
.dark .action-bar .search input::-webkit-input-placeholder { color :#657085; }
.dark .action-bar .search input::placeholder { color :#657085; }
.action-bar .search input:focus { width: 300px; background: #F9F9F9; cursor: text; transition: all .6s cubic-bezier(.26,2,.46,.71) 0s; }
.dark .action-bar .search input:focus { background: #343b49; }
.action-bar .search i { position: absolute; top: 50%; -webkit-transform: translateY( -50% ); transform: translateY( -50% ); right: 20px; color: #999; margin-top: 1px; pointer-events: none; }
.rtl .action-bar .search i { right: auto; left: 20px; }
.dark .action-bar .search i { color: #657085; }
.action-bar .search i.icon-close { pointer-events: all; cursor: pointer; transition: all .3s ease; }
.action-bar .search i.icon-close:hover { -webkit-transform: translateY( -50% ) scale(1.2); transform: translateY( -50% ) scale(1.2); }

/* Actions */
.action-bar .actions { float: right; }
.rtl .action-bar .actions { float: left; }
.action-bar .actions .filter,
.action-bar .actions .delete { background: #F1F1F1; border-radius: 5px; font-size: 13px; width: 40px; height: 40px; line-height: 28px; border: none; color: #FF6B6B; cursor: pointer; outline: none; margin-right: 10px; transition: all .3s ease; }
.action-bar .actions .delete { opacity: 0; visibility: hidden; position: relative; top: -20px; -webkit-animation: showDelete .3s ease-in-out forwards; animation: showDelete .3s ease-in-out forwards; }
.rtl .action-bar .actions .filter,
.rtl .action-bar .actions .delete { margin-right: 0; margin-left: 10px; }
.dark .action-bar .actions .filter,
.dark .action-bar .actions .delete { background: #292f3a; }
.action-bar .actions .filter { color: #0984E3; width: auto; padding: 0 20px; }
.action-bar .actions .filter i { position: relative; top: 1.5px; margin-right: 3px; }
.rtl .action-bar .actions .filter i { margin-right: 0; margin-left: 3px; top: 2px; }
.action-bar .actions .filter:hover,
.action-bar .actions .delete:hover { background: #E9E9E9; }
.dark .action-bar .actions .filter:hover,
.dark .action-bar .actions .delete:hover { background: #353c49; }
.action-bar .actions .add { height: 40px; border: none; background: #925b97; color: #FFF; border-radius: 5px; padding: 0 20px; cursor: pointer; position: relative; overflow: hidden; outline: none; }
.action-bar .actions .add i { font-size: 12px; position: relative; top: 1px; margin-right: 3px; }
.rtl .action-bar .actions .add i { margin-right: 0; margin-left: 3px; top: 2px; }
.action-bar .actions .add::before { content: ""; position: absolute; width: 150%; height: 10px; opacity: .3; background: #FFF; left: -150px; top: -20px; -webkit-transform: rotate(-45deg); transform: rotate(-45deg); transition: all .3s ease; }
.action-bar .actions .add:hover::before { left: 100px; top: 20px; }
.action-bar .actions .add.secondary { background: transparent; border: 1px solid #925b97; margin: 0 10px; color: #925b97; transition: all .3s ease; }
.action-bar .actions .add.secondary:hover { background: #925b97; color: #FFF; }


@-webkit-keyframes showDelete {
    0% {
        opacity: 0;
        visibility: hidden;
        top: -20px;
    }
    100% {
        opacity: 1;
        visibility: visible;
        top: 0;
    }
}


@keyframes showDelete {
    0% {
        opacity: 0;
        visibility: hidden;
        top: -20px;
    }
    100% {
        opacity: 1;
        visibility: visible;
        top: 0;
    }
}
.search-image { position: fixed; top: 150px; right: 0; height: calc(100vh - 150px); }
.rtl .search-image { right: auto; left: 0; -webkit-transform: rotateY(180deg); transform: rotateY(180deg); }
.rtl .search-client-actions .actions { text-align: left!important; }

.rtl .profiles { text-align: right!important; }
.rtl .profiles > button { float: left!important; }
.profiles .dashboard-table { padding: 0; }

.filters {
    position: absolute;
    top: 35px;
    right: 70px;
    z-index: 1000;
    width: 100%;
    text-align: right;
}

.filters .filter {
    min-width: 230px;
    display: inline-block;
    margin-left: 20px;
}

.details-table form .react-select { margin: 0; }
.details-table form .react-select .react-select__control { border-radius: 0; background: #eee; }

.client-attachments {
    border: 1px solid #925b97;
    padding: 40px 20px;
    border-radius: 8px;
    position: relative;
}
.client-attachments label {
    position: absolute;
    top: 0;
    left: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #FFF;
    color: #925b97;
    padding: 0 20px;
}

.force i {
    color: #AAA;
    transition: all .3s ease;
}
.force:hover i {
    color: #b950c3;
}
.attachments{text-align:start}.attachments ul{padding:0;margin:0;list-style:none}.attachments ul li{background:#f1f1f1;border-radius:8px;margin-bottom:10px;transition:all .3s ease}.attachments ul li a{display:block;padding:20px;color:#333;text-decoration:none}.attachments ul li a i{position:relative;top:3px;-webkit-margin-end:10px;margin-inline-end:10px;color:#925b97}.attachments ul li:hover{background:#eaeaea}.attachments ul li:last-of-type{margin-bottom:0}
.questions .question header { background: #F1F1F1; height: 60px; border-radius: 5px; border: 1px solid #DDD; border-bottom: 0; }
.questions .question header input { height: 50px; margin-top: 5px; background: transparent; border: none; padding-left: 20px; outline: none; font-size: 15px; width: 100%; font-weight: bold; }
.questions .question ul { list-style: none; padding: 0; margin: 0; background: #F9F9F9; border-radius: 0 0 5px 5px; border: 1px solid #DDD; border-top: 0; margin-top: -3px; }
.questions .question ul li { border-bottom: 1px solid #DDD; position: relative; }
.questions .question ul li:last-of-type { border: none; }
.questions .question ul li input.answer { border: none; background: transparent; padding-left: 40px; height: 70px; outline: none; width: 100%; font-size: 14px; }
.questions .question ul li .percentage { position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); right: 20px; width: 130px; }
.questions .question ul li .percentage input { background: #FFF; border: 1px solid #DDD; }
/* Font */

/* Main elements */
body { margin: 0; overflow-x: hidden; }
body.dark { background: #202835; }
* { box-sizing: border-box; direction: ltr; font-family: "Roboto", Arial, Helvetica, sans-serif; }
.rtl * { font-family: "Neo sans arabic", Arial, Helvetica, sans-serif; direction: rtl; }
img { max-width: 100%; height: auto; }

/* Main classes */
.text-center { text-align: center; }
.text-left { text-align: left; }
.rtl .text-left { text-align: right; }
.text-right { text-align: right; }
.center { position: absolute; top: 50%; left: 50%; -webkit-transform: translate( -50%, -50% ); transform: translate( -50%, -50% ); }
.clear { clear: both; }

/* Colors */
.color-gold { color: #925b97; }
.bg-gold { background-color: #925b97; }
.color-white { color: #FFF; }
.bg-white { background: #FFF; }
.bg-gray { background: #F9F9F9; }

/* Margin */
.margin-5 { display: inline-block; margin: 0 2.5px; }
.margin-10 { display: inline-block; margin: 0 5px; }
.margin-20 { display: inline-block; margin: 0 10px; }
.margin-30 { display: inline-block; margin: 0 15px; }
.margin-40 { display: inline-block; margin: 0 20px; }
.margin-50 { display: inline-block; margin: 0 25px; }

.margin-top-5 { margin-top: 5px; }
.margin-top-10 { margin-top: 10px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-30 { margin-top: 30px; }
.margin-top-40 { margin-top: 40px; }
.margin-top-50 { margin-top: 50px; }

/* Buttons */
.button { border: none; line-height: 50px; padding: 0 20px; border-radius: 5px; cursor: pointer; text-decoration: none; outline: none; font-size: 14px; font-weight: 600; box-shadow: 1px 3px 3px rgba( 0, 0, 0, .06 ); max-width: 100%; transition: all .3s ease; }
.button.round { border-radius: 100px; }
.modal-secondary-botton { width: 100%; display: inline-block; border: none; line-height: 50px; border-radius: 10px; margin-top: 5px; background: #F1F1F1; color: #333; cursor: pointer; outline: none; transition: all .3s ease; }
.modal-secondary-botton:hover { background: #EAEAEA; }
.dark .modal-secondary-botton { background: #2b3544; color: #FFF; }
.dark .modal-secondary-botton:hover { background: #343e4e; }


/* Hide number field arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance:textfield; /* Firefox */ }

/* Collapse */
.ReactCollapse--collapse { transition: height 500ms; }

/* Tabs */
.tabs { margin: 40px 0 0; padding: 0; }
.tabs li { display: inline-block; background: #F9F9F9; line-height: 50px; padding: 0 20px; border-radius: 5px; cursor: pointer; border: 1px solid #EEE; font-size: 14px; font-weight: 600; transition: all .3s ease; }
.tabs li:nth-child(n + 2) { margin-left: 10px; }
.rtl .tabs li:nth-child(n + 2) { margin-left: 0; margin-right: 10px; }
.tabs li:hover { background: #F1F1F1; border-color: #DDD; }
.tabs li.active { background: #925b97; color: #FFF; border-color: #925b97; }


/* Table */
.table { width: 100%; border-spacing: 0; border-collapse: collapse; border-radius: 10px; overflow: hidden; }
.table tr > td,
.table tr > th { height: 40px; min-width: 50px; border: 1px solid #DDD; }
.table tr > th { background: #925b97; color: #FFF; border-color: #7f4385; font-size: 13px; }
.table tr:nth-of-type(odd) { background: #F9F9F9; }


@media only screen and (max-width: 768px) {
    /* Side nav */
    .side-nav { top: auto; -webkit-transform: translate(0, 0); transform: translate(0, 0); bottom: 10px; width: 90%; left: 5%; max-height: 70px; overflow-y: hidden; border-radius: 20px; text-align: left; z-index: 100; }
    .side-nav ul { white-space: nowrap; }
    .side-nav ul li { display: inline-block; height: auto; text-align: center; margin-top: 11.5px; padding: 0 20px; -webkit-transform: scale(.9); transform: scale(.9); }
    .side-nav ul li a { height: auto; text-decoration: none; }
    .side-nav ul li span { position: relative; top: 0; left: 0; -webkit-transform: translate(0, 0); transform: translate(0, 0); }
    .side-nav ul li.active, .side-nav ul li:hover { -webkit-transform: scale(1); transform: scale(1); }
    .side-nav ul li i { margin: 0; }
    .side-nav .active-box { display: none; }

    .side-nav { background: #FFF; box-shadow: 0 -1px 5px rgb(0, 0, 0, .1); width: 100%; left: 0; bottom: 0; border-radius: 20px 20px 0 0; }
    .side-nav ul li { width: 20%; padding: 0; }
    .side-nav ul li a { color: #999; }
    .side-nav ul li.active a { color: #75AECC; }

    /* Main side */
    .main-side { margin: 0; padding-bottom: 60px; border-radius: 0; }
    .main-side > * { padding: 10px 20px; }
    .main-side .content { padding-right: 20px; }

    .mobile-nomargin { margin: 0!important; }
    .mobile-nopadd { padding: 0!important; }


    /* Table */
    .dashboard-table { padding: 0; }
    .dashboard-table table { word-break: break-all; }
    .dashboard-table th:nth-child(n+3),
    .dashboard-table td:nth-child(n+3) { display: none; }
    .dashboard-table th:last-child,
    .dashboard-table td:last-child { display: table-cell; }
    .dashboard-table table tbody tr td .show-on-hover { opacity: 1; visibility: visible; }

    .action-bar { padding: 0; margin: 0 0 20px; text-align: center; }
    .action-bar h2 { float: none; margin: 0 0 10px; }
    .action-bar .search { float: none; }
    .action-bar .search input { width: 100%!important; }
    .action-bar .actions { float: none; margin: 20px 0 0; }


    #modal-container .modal-background .modal { max-height: 100vh; }
    #modal-container .modal-background .modal .modal-content { padding: 40px 20px 20px; width: 100vw; }
    #modal-container .modal-background .modal .modal-content > div { width: unset!important; }
    #modal-container .modal-background .modal .modal-content .icon-close { display: inline-block!important; position: absolute; top: 10px; right: 15px; font-size: 18px; cursor: pointer; padding: 10px; }
    .details-table { width: 100%; max-width: unset; }
    .details-table td:last-of-type { line-break: anywhere; }
}

