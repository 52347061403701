.search-image { position: fixed; top: 150px; right: 0; height: calc(100vh - 150px); }
.rtl .search-image { right: auto; left: 0; transform: rotateY(180deg); }
.rtl .search-client-actions .actions { text-align: left!important; }

.rtl .profiles { text-align: right!important; }
.rtl .profiles > button { float: left!important; }
.profiles .dashboard-table { padding: 0; }

.filters {
    position: absolute;
    top: 35px;
    right: 70px;
    z-index: 1000;
    width: 100%;
    text-align: right;
}

.filters .filter {
    min-width: 230px;
    display: inline-block;
    margin-left: 20px;
}

.details-table form .react-select { margin: 0; }
.details-table form .react-select .react-select__control { border-radius: 0; background: #eee; }

.client-attachments {
    border: 1px solid #925b97;
    padding: 40px 20px;
    border-radius: 8px;
    position: relative;
}
.client-attachments label {
    position: absolute;
    top: 0;
    left: 20px;
    transform: translateY(-50%);
    background: #FFF;
    color: #925b97;
    padding: 0 20px;
}

.force i {
    color: #AAA;
    transition: all .3s ease;
}
.force:hover i {
    color: #b950c3;
}