.dashboard-table { padding-right: 30px; margin-bottom: 20px; }
.rtl .dashboard-table { padding-left: 30px; padding-right: 0; }
.dashboard-table table { width: 100%; text-align: left; border-collapse: unset; border-spacing: 0 10px; position: relative; }
.rtl .dashboard-table table { text-align: right; }

/* Head */
.dashboard-table table thead tr { line-height: 30px; min-height: 50px; }
.dashboard-table table thead tr th { font-weight: medium; font-size: 13px; padding: 0 10px; color: #777; }
.dark .dashboard-table table thead tr th { color: #495161; }


/* Body */
.dashboard-table table tbody tr { line-height: 60px; position: relative; transform: translateX(0); transition: all .3s ease; }
.dashboard-table table tbody tr.active { transform: translateX(10px); }
.rtl .dashboard-table table tbody tr.active { transform: translateX(-10px); }
.dashboard-table table tbody tr::after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none; background: #FAFAFA; border-radius: 5px; transition: all .3s ease; }
.dark .dashboard-table table tbody tr::after { background: #222730; }
.dashboard-table table tbody tr:hover::after,
.dashboard-table table tbody tr.active::after { background: #F5F5F5; }
.dark .dashboard-table table tbody tr:hover::after,
.dark .dashboard-table table tbody tr.active::after { background: #292f3a; }
.dashboard-table table tbody tr td { position: relative; z-index: 1; font-size: 13px; padding: 15px 10px; line-height: 22px; }
.dark .dashboard-table table tbody tr td { color: #DDD; }
.dashboard-table table tbody tr td:first-of-type label { top: 5px; transform: scale(.8); }
.dashboard-table table tbody tr td:first-of-type label,
.dashboard-table table tbody tr td input.select-row { pointer-events: none; }
.dashboard-table table tbody tr td .show-on-hover { opacity: 0; visibility: hidden; text-align: center; transition: all .3s ease; }
.dashboard-table table tbody tr:hover td .show-on-hover { opacity: 1; visibility: visible; }
.dashboard-table table tbody tr td .react-select { width: 180px; }

.dashboard-table table tbody tr td .show-on-hover i { margin: 0 5px; width: 30px; height: 30px; line-height: 32px; text-align: center; background: #FFF; border-radius: 3px; display: inline-block;  cursor: pointer; transition: all .3s ease; }
.dashboard-table table tbody tr td .show-on-hover i:hover { transform: scale(1.2); }
.dark .dashboard-table table tbody tr td .show-on-hover i { background: #343b49; }
.dashboard-table table tbody tr td .show-on-hover i.icon-edit { color: #0984E3; }
.dashboard-table table tbody tr td .show-on-hover i.icon-delete { color: #FF6B6B; }

.dashboard-table table tbody tr td .reports a { text-decoration: none; }
.dashboard-table table tbody tr td .reports i { font-size: 14px; margin: 0 5px; width: 30px; height: 30px; line-height: 32px; text-align: center; background: #FFF; border-radius: 3px; display: inline-block; color: #333; transition: all .3s ease; }
.dark .dashboard-table table tbody tr td .reports i { color: #FFF; background: #343b49; }
.dashboard-table table tbody tr td .reports i:hover { transform: scale(1.2); color: #925b97; }
.dashboard-table table tbody tr td .reports i.icon-bill { font-size: 15px; }

.dashboard-table table tbody tr td .icon-star-o,
.dashboard-table table tbody tr td .icon-star-2 { font-size: 17px; margin-left: 17px; display: inline-block; color: #925b97; transition: all .3s ease; }
.dashboard-table table tbody tr td .icon-star-o:hover,
.dashboard-table table tbody tr td .icon-star-2:hover { transform: scale(1.3); }

.dashboard-table table tbody tr.table-loader { height: 60px; }
.dashboard-table table tbody tr.table-loader::after { display: none; }
.dashboard-table table tbody tr.table-loader .lds-ellipsis { transform: scale(.7); margin-top: 20px; }

.dashboard-table table tr.no-items { transform: translate(0); line-height: 55px; }
.dashboard-table table tr.no-items td { position: relative; font-size: 14px; color: #555; }
.dashboard-table table tr.no-items::before { content: ""; position: absolute; width: 100%; top: 0; left: 0; background: #F5F5F5; height: 60px; border-radius: 10px; }
.dark .dashboard-table table tr.no-items td { color: #FFF; }
.dark .dashboard-table table tr.no-items::before { background: #292f3a; }

/* Tags */
.tags { list-style: none; padding: 0; margin: 0; max-width: 300px; line-height: 40px; }
.tags li { display: inline-block; background: #EAEAEA; line-height: 35px; padding: 0 10px; border-radius: 5px; margin-right: 5px; cursor: pointer; transition: all .3s ease; }
.dark .tags li { background: #292f3a; }
.dark .dashboard-table table tbody tr:hover .tags li { background: #343b49; }
.tags li:hover { transform: scale(1.05); background: #925b97; color: #FFF; }

/* Print table */
.print-container { padding: 10px 30px; }
table.print-table { margin: 0 auto 0; width: 100%; }
table.print-table,
table.print-table th,
table.print-table td { border-collapse: collapse; border: 1px solid black; text-align: left; }
table.print-table { page-break-inside: avoid; }
table.print-table th,
table.print-table td { padding: 5px 10px; }
table.print-table th { font-weight: bold; }
.rtl table.print-table th,
.rtl table.print-table td { text-align: right; }