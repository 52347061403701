.action-bar { margin-bottom: 70px; padding-right: 30px; }
.rtl .action-bar { padding-left: 30px; padding-right: 0; }

.action-bar h2 { float: left; color: #444; font-size: 28px; font-weight: normal; margin: 0; padding: 0; line-height: 45px; margin-right: 30px; }
.dark .action-bar h2 { color: #636b7a; }
.rtl .action-bar h2 { float: right; margin-right: 0; margin-left: 30px; }

/* Search */
.action-bar .search { float: left; position: relative; }
.rtl .action-bar .search { float: right; }
.action-bar .search input { width: 200px; line-height: 45px; border-radius: 50px; border: 1px solid #DDD; padding: 0 20px; outline: none; cursor: pointer; transition: all .6s cubic-bezier(.26,2,.46,.71) .2s; }
.dark .action-bar .search input { background: #292f3a; border-color: #343b49; color: #FFF; }
.dark .action-bar .search input::placeholder { color :#657085; }
.action-bar .search input:focus { width: 300px; background: #F9F9F9; cursor: text; transition: all .6s cubic-bezier(.26,2,.46,.71) 0s; }
.dark .action-bar .search input:focus { background: #343b49; }
.action-bar .search i { position: absolute; top: 50%; transform: translateY( -50% ); right: 20px; color: #999; margin-top: 1px; pointer-events: none; }
.rtl .action-bar .search i { right: auto; left: 20px; }
.dark .action-bar .search i { color: #657085; }
.action-bar .search i.icon-close { pointer-events: all; cursor: pointer; transition: all .3s ease; }
.action-bar .search i.icon-close:hover { transform: translateY( -50% ) scale(1.2); }

/* Actions */
.action-bar .actions { float: right; }
.rtl .action-bar .actions { float: left; }
.action-bar .actions .filter,
.action-bar .actions .delete { background: #F1F1F1; border-radius: 5px; font-size: 13px; width: 40px; height: 40px; line-height: 28px; border: none; color: #FF6B6B; cursor: pointer; outline: none; margin-right: 10px; transition: all .3s ease; }
.action-bar .actions .delete { opacity: 0; visibility: hidden; position: relative; top: -20px; animation: showDelete .3s ease-in-out forwards; }
.rtl .action-bar .actions .filter,
.rtl .action-bar .actions .delete { margin-right: 0; margin-left: 10px; }
.dark .action-bar .actions .filter,
.dark .action-bar .actions .delete { background: #292f3a; }
.action-bar .actions .filter { color: #0984E3; width: auto; padding: 0 20px; }
.action-bar .actions .filter i { position: relative; top: 1.5px; margin-right: 3px; }
.rtl .action-bar .actions .filter i { margin-right: 0; margin-left: 3px; top: 2px; }
.action-bar .actions .filter:hover,
.action-bar .actions .delete:hover { background: #E9E9E9; }
.dark .action-bar .actions .filter:hover,
.dark .action-bar .actions .delete:hover { background: #353c49; }
.action-bar .actions .add { height: 40px; border: none; background: #925b97; color: #FFF; border-radius: 5px; padding: 0 20px; cursor: pointer; position: relative; overflow: hidden; outline: none; }
.action-bar .actions .add i { font-size: 12px; position: relative; top: 1px; margin-right: 3px; }
.rtl .action-bar .actions .add i { margin-right: 0; margin-left: 3px; top: 2px; }
.action-bar .actions .add::before { content: ""; position: absolute; width: 150%; height: 10px; opacity: .3; background: #FFF; left: -150px; top: -20px; transform: rotate(-45deg); transition: all .3s ease; }
.action-bar .actions .add:hover::before { left: 100px; top: 20px; }
.action-bar .actions .add.secondary { background: transparent; border: 1px solid #925b97; margin: 0 10px; color: #925b97; transition: all .3s ease; }
.action-bar .actions .add.secondary:hover { background: #925b97; color: #FFF; }


@keyframes showDelete {
    0% {
        opacity: 0;
        visibility: hidden;
        top: -20px;
    }
    100% {
        opacity: 1;
        visibility: visible;
        top: 0;
    }
}