.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

/* Ellipsis Loader */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #925b97;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

/* Whitebox loader */
.whitebox-loader { position: absolute; width: 100%; height: 100%; background: rgba(255, 255, 255, .5); top: 0; left: 0; z-index: 10; }
.dark .whitebox-loader { background: #1a202986; }


/* Success mark */
.success-holder { position: absolute; width: 100%; height: 100%; background: rgba(255, 255, 255, 0); top: 0; left: 0; z-index: 10; }
.modal .success-holder { animation: fadeBgIn .5s ease forwards; }
.dark .modal .success-holder { animation: fadeBgInDark .5s ease forwards; }
.success-checkmark {
	width: 80px;
	height: 115px;
    margin: -20px auto 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.success-checkmark .check-icon {
	width: 80px;
	height: 80px;
	position: relative;
	border-radius: 50%;
	box-sizing: content-box;
	border: 4px solid #925b97;
}

.success-checkmark .check-icon::before {
	top: 3px;
	left: -2px;
	width: 30px;
	transform-origin: 100% 50%;
	border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
	top: 0;
	left: 30px;
	width: 60px;
	transform-origin: 0 50%;
	border-radius: 0 100px 100px 0;
	animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
	content: '';
	height: 100px;
	position: absolute;
	background: #FFFFFF;
	transform: rotate(-45deg);
}
.dark .success-checkmark .check-icon::before,
.dark .success-checkmark .check-icon::after {
    background: #323b4b;
}

.success-checkmark .check-icon .icon-line {
	height: 5px;
	background-color: #925b97;
	display: block;
	border-radius: 2px;
	position: absolute;
	z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
	top: 46px;
	left: 14px;
	width: 25px;
	transform: rotate(45deg);
	animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
	top: 38px;
	right: 8px;
	width: 47px;
	transform: rotate(-45deg);
	animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
	top: -4px;
	left: -4px;
	z-index: 10;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: absolute;
	box-sizing: content-box;
	border: 4px solid rgba(211, 53, 114, 0);
}

.success-checkmark .check-icon .icon-fix {
	top: 8px;
	width: 5px;
	left: 26px;
	z-index: 1;
	height: 85px;
	position: absolute;
	transform: rotate(-45deg);
	background-color: #FFFFFF;
}
.dark .success-checkmark .check-icon .icon-fix {
    background-color: #323b4b;
}

@keyframes rotate-circle {
	0% {
		transform: rotate(-45deg);
	}
	5% {
		transform: rotate(-45deg);
	}
	12% {
		transform: rotate(-405deg);
	}
	100% {
		transform: rotate(-405deg);
	}
}

@keyframes icon-line-tip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@keyframes icon-line-long {
	0% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

@keyframes fadeBgIn {
    0% {
        background: rgba(255, 255, 255, 0);
    }
    100% {
        background: rgba(255, 255, 255, 1);
    }
}
@keyframes fadeBgInDark {
    0% {
        background: #323b4b00;
    }
    100% {
        background: #323b4b;
    }
}


/* Colorfull loader */
.cssload-dots {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	outline: 1px solid rgb(35, 174, 230);
	filter: url(#goo);
		-o-filter: url(#goo);
		-ms-filter: url(#goo);
		-webkit-filter: url(#goo);
		-moz-filter: url(#goo);
}

.cssload-dot {
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
}
.cssload-dot:before {
	content: "";
	width: 34px;
	height: 34px;
	/* border-radius: 49px; */
	background: rgb(26,188,156);
	position: absolute;
	left: 50%;
	transform: translateY(0);
		-o-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
	margin-left: -17.5px;
	margin-top: -17.5px;
}



.cssload-dot:nth-child(5):before {
	z-index: 100;
	width: 44.5px;
	height: 44.5px;
	margin-left: -21.75px;
	margin-top: -21.75px;
	animation: cssload-dot-colors 4.6s ease infinite;
		-o-animation: cssload-dot-colors 4.6s ease infinite;
		-ms-animation: cssload-dot-colors 4.6s ease infinite;
		-webkit-animation: cssload-dot-colors 4.6s ease infinite;
		-moz-animation: cssload-dot-colors 4.6s ease infinite;
}


.cssload-dot:nth-child(1) {
	animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-o-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-ms-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-webkit-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-moz-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
}
.cssload-dot:nth-child(1):before {
	background-color: rgb(35, 174, 230);
	animation: cssload-dot-move 4.6s 0s ease infinite;
		-o-animation: cssload-dot-move 4.6s 0s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 0s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 0s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 0s ease infinite;
}

.cssload-dot:nth-child(2) {
	animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-o-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-ms-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-webkit-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-moz-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
}
.cssload-dot:nth-child(2):before {
	background-color: rgb(58,230,230);
	animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-o-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 1.15s ease infinite;
}

.cssload-dot:nth-child(3) {
	animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-o-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-ms-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-webkit-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-moz-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
}
.cssload-dot:nth-child(3):before {
	background-color: rgb(58,230,230);
	animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-o-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 2.3s ease infinite;
}

.cssload-dot:nth-child(4) {
	animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-o-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-ms-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-webkit-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-moz-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
}
.cssload-dot:nth-child(4):before {
	background-color: rgb(26,188,156);
	animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-o-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 3.45s ease infinite;
}

@keyframes cssload-dot-move {
	0% {
		transform: translateY(0);
	}
	18%, 22% {
		transform: translateY(-68px);
	}
	40%, 100% {
		transform: translateY(0);
	}
}

@-o-keyframes cssload-dot-move {
	0% {
		-o-transform: translateY(0);
	}
	18%, 22% {
		-o-transform: translateY(-68px);
	}
	40%, 100% {
		-o-transform: translateY(0);
	}
}

@-ms-keyframes cssload-dot-move {
	0% {
		-ms-transform: translateY(0);
	}
	18%, 22% {
		-ms-transform: translateY(-68px);
	}
	40%, 100% {
		-ms-transform: translateY(0);
	}
}

@-webkit-keyframes cssload-dot-move {
	0% {
		-webkit-transform: translateY(0);
	}
	18%, 22% {
		-webkit-transform: translateY(-68px);
	}
	40%, 100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes cssload-dot-move {
	0% {
		-moz-transform: translateY(0);
	}
	18%, 22% {
		-moz-transform: translateY(-68px);
	}
	40%, 100% {
		-moz-transform: translateY(0);
	}
}

@keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(58,230,230);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@-o-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(58,230,230);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@-ms-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(58,230,230);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@-webkit-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(58,230,230);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@-moz-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(26,188,156);
	}
	25% {
		background-color: rgb(35, 174, 230);
	}
	50% {
		background-color: rgb(10, 190, 121);
	}
	75% {
		background-color: rgb(58,230,230);
	}
	100% {
		background-color: rgb(26,188,156);
	}
}

@keyframes cssload-dot-rotate-1 {
	0% {
		transform: rotate(-105deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

@-o-keyframes cssload-dot-rotate-1 {
	0% {
		-o-transform: rotate(-105deg);
	}
	100% {
		-o-transform: rotate(270deg);
	}
}

@-ms-keyframes cssload-dot-rotate-1 {
	0% {
		-ms-transform: rotate(-105deg);
	}
	100% {
		-ms-transform: rotate(270deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-1 {
	0% {
		-webkit-transform: rotate(-105deg);
	}
	100% {
		-webkit-transform: rotate(270deg);
	}
}

@-moz-keyframes cssload-dot-rotate-1 {
	0% {
		-moz-transform: rotate(-105deg);
	}
	100% {
		-moz-transform: rotate(270deg);
	}
}

@keyframes cssload-dot-rotate-2 {
	0% {
		transform: rotate(165deg);
	}
	100% {
		transform: rotate(540deg);
	}
}

@-o-keyframes cssload-dot-rotate-2 {
	0% {
		-o-transform: rotate(165deg);
	}
	100% {
		-o-transform: rotate(540deg);
	}
}

@-ms-keyframes cssload-dot-rotate-2 {
	0% {
		-ms-transform: rotate(165deg);
	}
	100% {
		-ms-transform: rotate(540deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-2 {
	0% {
		-webkit-transform: rotate(165deg);
	}
	100% {
		-webkit-transform: rotate(540deg);
	}
}

@-moz-keyframes cssload-dot-rotate-2 {
	0% {
		-moz-transform: rotate(165deg);
	}
	100% {
		-moz-transform: rotate(540deg);
	}
}

@keyframes cssload-dot-rotate-3 {
	0% {
		transform: rotate(435deg);
	}
	100% {
		transform: rotate(810deg);
	}
}

@-o-keyframes cssload-dot-rotate-3 {
	0% {
		-o-transform: rotate(435deg);
	}
	100% {
		-o-transform: rotate(810deg);
	}
}

@-ms-keyframes cssload-dot-rotate-3 {
	0% {
		-ms-transform: rotate(435deg);
	}
	100% {
		-ms-transform: rotate(810deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-3 {
	0% {
		-webkit-transform: rotate(435deg);
	}
	100% {
		-webkit-transform: rotate(810deg);
	}
}

@-moz-keyframes cssload-dot-rotate-3 {
	0% {
		-moz-transform: rotate(435deg);
	}
	100% {
		-moz-transform: rotate(810deg);
	}
}

@keyframes cssload-dot-rotate-4 {
	0% {
		transform: rotate(705deg);
	}
	100% {
		transform: rotate(1080deg);
	}
}

@-o-keyframes cssload-dot-rotate-4 {
	0% {
		-o-transform: rotate(705deg);
	}
	100% {
		-o-transform: rotate(1080deg);
	}
}

@-ms-keyframes cssload-dot-rotate-4 {
	0% {
		-ms-transform: rotate(705deg);
	}
	100% {
		-ms-transform: rotate(1080deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-4 {
	0% {
		-webkit-transform: rotate(705deg);
	}
	100% {
		-webkit-transform: rotate(1080deg);
	}
}

@-moz-keyframes cssload-dot-rotate-4 {
	0% {
		-moz-transform: rotate(705deg);
	}
	100% {
		-moz-transform: rotate(1080deg);
	}
}